<script lang="ts" setup>
const props = defineProps({
  showFree: {
    type: Boolean,
    default: true,
  },
  align: {
    type: String,
    default: "bottom",
  },
});

const { checkout } = useCheckout();
const user = useSanctumUser<User>();
const { t } = useI18n();
const localePath = useLocalePath();
const { products } = useProducts();
const featuresFreePlan = [
  {
    title: t("pricing_cards.free.features.1.title"),
    description: t("pricing_cards.free.features.1.description"),
  },
  {
    title: t("pricing_cards.free.features.2.title"),
    description: t("pricing_cards.free.features.2.description"),
  },
  {
    title: t("pricing_cards.free.features.3.title"),
    description: t("pricing_cards.free.features.3.description"),
  },
  {
    title: t("pricing_cards.free.features.4.title"),
    description: t("pricing_cards.free.features.4.description"),
  },
  {
    title: t("pricing_cards.free.features.5.title"),
    description: t("pricing_cards.free.features.5.description"),
  },
  {
    title: t("pricing_cards.free.features.6.title"),
    description: t("pricing_cards.free.features.6.description"),
  },
];
const priceSelected = ref<Price | null>(null);
const productSelected = ref<Product | null>(null);
const pending = ref(false);
const { getReasonForPurchase } = usePurchase();
const start = async (price: Price, product: Product) => {
  priceSelected.value = price;
  productSelected.value = product;
  const { sourceTool, reasonForPurchase } = getReasonForPurchase();
  sessionStorage.setItem("price_interval", price.interval);
  sessionStorage.setItem("product_slug", product.slug);
  if (user.value) {
    pending.value = true;

    await checkout(product.slug, price.interval, sourceTool, reasonForPurchase);
    pending.value = false;
  } else {
    navigateTo(localePath("register"));
  }
};

// const isYearly = ref(false);
</script>

<template>
  <div>
    <!-- <div class="flex justify-center mb-16">
      <div class="w-80 relative">
        <UPricingToggle
          v-model="isYearly"
          :ui="{
            marker: 'bg-primary-500',
          }"
          :left="t('pricing_cards.monthly')"
          :right="t('pricing_cards.yearly')"
          class="max-w-xs"
        />
        <UBadge
          size="xs"
          :ui="{ rounded: 'rounded-full' }"
          class="absolute top-1/2 -translate-y-1/2 -right-24 hidden lg:block"
        >
          {{ t("pricing_cards.save") }}
        </UBadge>
      </div>
    </div> -->
    <UPricingGrid
      :class="
        showFree ? 'lg:grid-cols-3 gap-12 mt-6' : 'lg:grid-cols-2 gap-12 mt-6'
      "
    >
      <UPricingCard
        v-if="showFree"
        :title="t('pricing_cards.free.title')"
        price="-"
        discount=""
        :highlight="false"
        :button="{
          label: t('pricing_cards.button'),
          color: 'white',
          click: () =>
            navigateTo(
              user ? localePath('/dashboard') : localePath('register'),
            ),
        }"
        :align="props.align === 'bottom' ? 'bottom' : 'top'"
        class="order-2 lg:order-1"
      >
        <template #features>
          <ul class="text-sm space-y-2 flex flex-col">
            <UTooltip
              v-for="feature in featuresFreePlan"
              :key="feature.title"
              mode="hover"
              :text="feature.description"
            >
              <li class="flex items-center gap-x-2">
                <UIcon
                  name="i-ph-check-fat-fill"
                  class="text-green-400 text-lg"
                />
                <span>{{ feature.title }}</span>
              </li>
            </UTooltip>
          </ul>
        </template>
      </UPricingCard>
      <template v-for="product in products" :key="product.id">
        <template v-for="price in product.prices" :key="price.id">
          <UPricingCard
            :ui="{
              wrapper: product.highlight
                ? 'shadow-2xl shadow-primary-300'
                : 'relative flex flex-col self-stretch w-full',
              body: {
                base: 'flex-1 gap-6 lg:gap-x-8 xl:gap-x-10 flex flex-col rounded-xl',
                padding: 'p-6 lg:p-6 xl:p-6',
                background: product.highlight
                  ? 'bg-gradient-to-b from-primary-50 to-white'
                  : '',
              },
            }"
            :title="
              product.name +
              ' - ' +
              (price.interval === 'year'
                ? t('pricing_cards.yearly')
                : price.interval === 'month'
                  ? t('pricing_cards.monthly')
                  : t('pricing_cards.weekly'))
            "
            :price="price.currency.symbol + price.amount_formatted"
            :cycle="
              price.currency.currency +
              '/' +
              (price.interval === 'year'
                ? t('pricing_cards.every_year')
                : price.interval === 'month'
                  ? t('pricing_cards.every_month')
                  : t('pricing_cards.every_week'))
            "
            :badge="
              price.interval === 'week'
                ? { label: t('pricing_cards.popular') }
                : undefined
            "
            :scale="price.interval === 'week' ? true : false"
            :highlight="price.interval === 'week' ? true : false"
            :button="{
              label: t('pricing_cards.button'),
              loading:
                pending &&
                priceSelected?.id === price.id &&
                productSelected?.id === product.id,
              disabled: pending,
              color: price.interval === 'week' ? 'primary' : 'white',
              click: () => start(price, product),
            }"
            :align="props.align === 'bottom' ? 'bottom' : 'top'"
            :class="
              product.highlight ? 'order-1 lg:order-2' : 'order-3 lg:order-3'
            "
          >
            <template #features>
              <ul class="text-sm space-y-2 flex flex-col">
                <UTooltip
                  v-for="feature in product.features"
                  :key="feature.title"
                  :text="feature.description"
                  :popper="{
                    arrow: true,
                    resize: true,
                  }"
                  :ui="{
                    base: 'text-wrap h-auto',
                  }"
                >
                  <li class="flex items-center gap-x-2">
                    <Icon :name="feature.icon" class="text-green-400 text-lg" />
                    <span>{{ feature.title }}</span>
                  </li>
                </UTooltip>
                <UTooltip
                  :text="
                    price.interval === 'week'
                      ? t('pricing_cards.pro.week.tooltip')
                      : t('pricing_cards.pro.month.tooltip')
                  "
                  :popper="{
                    arrow: true,
                    resize: true,
                  }"
                  :ui="{
                    base: 'text-wrap h-auto',
                  }"
                >
                  <li class="flex items-center gap-x-2">
                    <Icon
                      name="i-ph-check-fat-fill"
                      class="text-green-400 text-lg"
                    />
                    <span v-if="price.interval === 'week'">
                      {{ t("pricing_cards.pro.week.feature") }}
                    </span>
                    <span v-if="price.interval === 'month'">
                      {{ t("pricing_cards.pro.month.feature") }}
                    </span>
                  </li>
                </UTooltip>
              </ul>
            </template>
          </UPricingCard>
        </template>
      </template>
    </UPricingGrid>
  </div>
</template>

<style></style>
